import React from 'react'

import { WWdiv } from './styled'

const WWHR = (props) => (
  <WWdiv { ...props }>
    { props.children }
  </WWdiv>
)

export default WWHR
