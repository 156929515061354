import styled from 'styled-components'

import animate from '@keyframes'

export const Header = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50000;
  height: 76px;
  transition: all 0.15s ease-in-out 0.5s;
  &.scroll.headerHidden {
    top: -76px;
  }
`

// .headerHidden for headerRevealMethod 'scroll' must occur in nav-bar because we're leaving the logo on the left at all times
