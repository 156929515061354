import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const GLink = ({ children, to, activeClassName, href, partiallyActive, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to) // excludes fragment hashes, which it should
  // Use Gatsby Link for internal links, and <a> for others
  // console.log({ to, ...other })
  if (internal) {
    return (
      <GatsbyLink to={ to || href || undefined }
        activeClassName={ activeClassName }
        partiallyActive={ partiallyActive }
        { ...other }
      >
        { children }
      </GatsbyLink>
    )
  }
  return (
    <a href={ to || href || undefined }
      { ...other }
      rel="noopener noreferrer"
    >
      { children }
    </a>
  )
}

export default GLink
