import { createContext } from 'react'

const defaultState = {
  h: 0,
  w: 0,
  cx: 0,
  cy: 0,
}

const WindowContext = createContext(defaultState)

export default WindowContext
