import React from 'react'
import classNames from 'classnames'

import { Header } from './styled'
import NavBar from '@components/nav-bar'

export default function PageHeaderVisible ({ render = true, reveal = true, revealMethod = 'scroll' }) {
  return render
    ? (
      <>
        <Header className={ classNames(
          {
            headerHidden: !reveal,
            [revealMethod]: true,
          },
        ) }
        >
          <NavBar />
        </Header>
      </>
    )
    : ''
}
