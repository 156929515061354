import styled from 'styled-components'

import { media } from '@styles'

export const Footer = styled.footer`
  color: var(--color-black);
  padding-bottom: 8em;

  @nest & a {
    color: var(--color-hilite);
    text-decoration: none;

    @media (hover: hover) {
      @nest &:hover {
        color: var(--color-hilite-hover);
      }
    }
  }
`
export const FooterInner = styled.div`
  padding: 4.5em 1.5em 1.5em;
  margin: 0 auto;

  @media (--media-min-small) {
    padding: 6em 2em 2em;
  }
`

export const SiteInfoBlock = styled.div`
  text-align: center;
  font-size: var(--font-small-size);
  line-height: 1.7;
`

export const TechLinksWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin: 8px auto;
  width: 60vw;
  ${media.minWidth.tablet`
    width: 40vw;
  `}
  ${media.minWidth.large`
    width: 30vw;
  `}
`

export const TechLink = styled.a.attrs(props => ({
  'aria-label': props['aria-label'] || '',
  href: props.href || '',
  target: props.target || '_self',
  title: props.title || '',
}))`
  display: inline-block;
  position: relative;
  width: 8.58vw;
  height: 8.58vw;
  ${media.minWidth.tablet`
    width: 5.72vw;
    height: 5.72vw;
  `}
  ${media.minWidth.large`
    width: 4.29vw;
    height: 4.29vw;
  `}
  img {
    height: 100%;
  }
`
