import React, { useEffect, useState } from 'react'

import { loadJavaScriptInto, toQueryString } from '@utils'
import { GlobeWrapper } from './styled'

const RevolvermapsGlobe = (props) => {
  const globeParams = {
    i: props.i || '9s1ics03n9b',
    m: props.m || '0c',
    s: props.s || '341',
    c: props.c || 'ff0000',
    cr1: props.cr1 || 'ffffff',
    f: props.f || 'lucida_sans_unicode',
    l: props.l || 1,
    cw: props.cw || 'ffffff',
    cb: props.cb || '000000',
  }

  const [ globeLoaded, setGlobeLoaded ] = useState(false)

  useEffect(() => {
    loadJavaScriptInto('revolvermaps-globe', null, `https://rj.revolvermaps.com/0/0/6.js?${toQueryString(globeParams)}`, () => { setGlobeLoaded(true) })
  }, []) // RUN ONCE ON LOAD

  return <GlobeWrapper id='revolvermaps-globe' />
}

export default RevolvermapsGlobe
