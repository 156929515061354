import React from 'react'

import WWHR from '@components/html/ww-hr'
import { EmH1 } from '@styles'

const Subscribe = () => {
  return (
    <>
      <WWHR><a name='subscribe' /></WWHR>
      <EmH1><a href='https://eepurl.com/gW7paH'
        target='_blank'
      >CLICK HERE to SUBSCRIBE to our MAILING LIST!</a></EmH1>
    </>
  )
}

export default Subscribe
