import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { FragmentSanityTree, FragmentSanityTreeItem } from '@fragments'
import WWHR from '@components/html/ww-hr'
import PositiveSSLTrustSeal from './positivessl-trust-seal'
import { Footer, FooterInner, SiteInfoBlock, TechLink, TechLinksWrapper } from './styled'

const techTreeQuery = graphql`
  query TechTreeQuery {
    techTree: sanityTree(slug: { current: {eq: "tech"}}) {
      ...SanityTreeOwnFields
      items {
        ...SanityTreeItem
      }
    }
  }
`

export default function PageFooterVisible ({ render = true }) {
  const {
    techTree: {
      items,
    },
  } = useStaticQuery(techTreeQuery)

  return render
    ? (
      <Footer>
        <WWHR className='wafer-thin' />
        <FooterInner>
          <SiteInfoBlock>
            <strong>©{ new Date().getFullYear() } Billy Z Duke</strong><br />painstakingly built by hand with:
            <TechLinksWrapper className='clearUnfix'>
              {
                items.map((item, i) => (
                  <TechLink key={ i }
                    href={ item.reference.href }
                    target={ item.reference.target }
                    title={ item.title }
                    ariaLabel={ item.title }
                  >
                    <img src={ item.image.asset.url }
                      aria-hidden
                    />
                  </TechLink>
                ))
              }
            </TechLinksWrapper>
          </SiteInfoBlock>
          { /* <PositiveSSLTrustSeal /> */ }
        </FooterInner>
      </Footer>
    )
    : ''
}
