import React, { Fragment, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'

import Arrow from './arrow'
import { FragmentSanityTree, FragmentSanityTreeItem } from '@fragments'

import {
  NavBarWrapper,
  NavLinksList,
  NavLinkItem,
  NavLinkAnchor,
  SubNavBarWrapper,
  SubNavArrow,
  SubNavLinksList,
  SubNavLinkItem,
  SubNavLinkAnchor,
  HomeLinkItem,
  HomeLinkAnchor,
} from './styled'

// unfortunately you have to know in advance how many levels the tree has to properly form the query
// and sanity's new nested snippet confirms this!
const navTreeQuery = graphql`
  query NavTreeQuery {
    navTree: sanityTree(slug: { current: {eq: "nav-bar"}}) {
      ...SanityTreeOwnFields
      items {
        ... on SanityTree {
          ...SanityTreeOwnFields
          items {
            ... on SanityTree {
              ...SanityTreeOwnFields
              items {
                ...SanityTreeItem
              }
            }
            ...SanityTreeItem
          }
        }
        ...SanityTreeItem
      }
    }
  }
`

const NavBar = () => {
  const {
    navTree: {
      items: [ home, { items: navItems } ],
    },
  } = useStaticQuery(navTreeQuery)
  const [ subNavOpen, setSubNavOpen ] = useState({})
  const toggleSubNavOpen = (itemId) => {
    // console.log(itemId)
    const updatedSubNavOpen = {}
    updatedSubNavOpen[itemId] = !subNavOpen[itemId]
    setSubNavOpen({
      ...subNavOpen,
      ...updatedSubNavOpen,
    })
  }

  const breakSubNavLabel = title => {
    if (title.indexOf('$') !== 0 && title.indexOf('SHOP') !== 0) return title
    const splitter = (title.indexOf('@') === -1) ? 'RI' : '('
    const [ befo, afta ] = title.split(splitter)
    return (
      <Fragment key={ title }>
        { befo }<br />{ splitter }{ afta }
      </Fragment>
    )
  }

  const listSubNavAnchor = item => (
    <>
      <SubNavLinkAnchor key={ `${item.id}_sm` }
        title='item.title'
        className='navLink mobileAndSmaller'
        href={ item.reference.href || undefined }
        target={ item.reference.target || undefined }
        aria-label={ item.reference.title || undefined }
      ><span className='l337'>{ item.l33tTitle }</span><span className='legible'>{ item.title }!</span></SubNavLinkAnchor>
      <SubNavLinkAnchor key={ `${item.id}_lg` }
        title='item.title'
        className='navLink tabletAndLarger'
        href={ item.reference.href || undefined }
        target={ item.reference.target || undefined }
        aria-label={ item.reference.title || undefined }
      ><span className='l337'>{ breakSubNavLabel(item.l33tTitle) }</span><span className='legible'>{ breakSubNavLabel(item.title) }!</span></SubNavLinkAnchor>
    </>
  )

  const listSubNavItem = item => <SubNavLinkItem key={ item.id }>{ listSubNavAnchor(item) }</SubNavLinkItem>

  const listSubNavItems = item => {
    const itemsList = item.items
    if (!itemsList || itemsList.length === 0) return
    return (
      <SubNavBarWrapper key={ `${item.id}_sub` }
        className={ classNames({
          sub: true,
          open: subNavOpen[item.id],
        }) }
      >
        <div className='trap trapShadow' />
        <div className='trap trapBlack'>
          <div className='trap trapWhite'>
            <SubNavLinksList className='trap'>
              { itemsList.map(item => listSubNavItem(item)) }
            </SubNavLinksList>
          </div>
        </div>
      </SubNavBarWrapper>
    )
  }

  const listNavAnchor = item => <NavLinkAnchor key={ item.id }
    title={ item.title }
    className='navLink'
    href={ item.reference ? item.reference.href || undefined : undefined }
    target={ item.reference ? item.reference.target || undefined : undefined }
    onClick={ item.reference ? undefined : () => toggleSubNavOpen(item.id) }
    aria-label={ item.title }
    aria-haspopup={ !item.reference }
  ><span className='l337'>{ item.l33tTitle }</span><span className='legible'>{ `${item.title}!` }{ item.items && subNavOpen[item.id] ? <><Arrow /><Arrow /><Arrow /></> : '' }</span></NavLinkAnchor>

  const listNavItem = item => item.active || item.items
    ? (
      <Fragment key={ `${item.id}_frag` }>
        <NavLinkItem key={ item.id }
          className={ classNames({
            hovered: subNavOpen[item.id],
          }) }
        >{ listNavAnchor(item) }</NavLinkItem>
        { item.items
          ? (<Fragment key={ `${item.id}_sub_frag` }>{ listSubNavItems(item) }</Fragment>)
          : ''
        }
      </Fragment>
    )
    : ''

  const listNavItems = itemsList => itemsList.map(item => listNavItem(item))

  return (
    <>
      <NavBarWrapper>
        <NavLinksList>
          { listNavItems(navItems) }
        </NavLinksList>
      </NavBarWrapper>
      <HomeLinkItem as='div'
        id='home'
      >
        <HomeLinkAnchor href={ home.reference.href || undefined }
          title={ home.l33tTitle }
          aria-label={ home.title }
        >
          <img src={ home.image.asset.url }
            height='52'
            alt={ home.image.asset.altText }
          />
        </HomeLinkAnchor>
      </HomeLinkItem>
    </>
  )
}

export default NavBar
