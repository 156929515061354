import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { clearFix } from 'polished'

import { media, setRgba } from '@styles'
import animate from '@keyframes'
import WWineBoxTileAss from '@raster/WWineBoxBG.jpg'

const GlobalStyles = createGlobalStyle`
  ${normalize}

  @property --color-stop {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 50;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  div,
  section {
    position: relative;
  }
  div:not(.clearUnfix):not(.clearUnfix *) {
    ${clearFix()}
  }

  main {
    text-align: center;
    width: 100%;
    /* opacity: 0;
    ${animate.fadeIn({ delay: 1 })} */
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: ${setRgba('themePrimeGrey')};
    background-image: url('${WWineBoxTileAss}');
    background-position: center top;
    background-repeat: repeat;
    font-family: Monaco, Menlo, "Lucida Sans Unicode", monospace;
    color: ${setRgba('siteWhite')};
    overflow-x: hidden;
    &.nekkid {
      background-color: black;
      overflow-x: hidden;
      ${media.minWidth.xLarge`
        overflow-y: hidden;
      `}
      article,
      main {
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vw;
      }
      &#pixi {
        background-image: none;
      }
    }
    &#test {
      background-image: none;
      background-color: #00b140;
    }
    iframe & {
      top: 0 !important;
      * {
        position: absolute !important;
        top: 0 !important;
      }
    }
  }

  .page {
    top: 0;
  }

  a,
  a:visited {
    color: ${setRgba('anchorOff')};
    text-decoration: none;
    transition: color 0.25s ease-in-out;
  }
  a:not(.navLink):hover,
  a:not(.navLink):active {
    ${animate.pulseAura({ color: setRgba('oppositeOrange'), maxSize: '0.94117647vw', duration: 0.32 })}
  }
  a:hover,
  a:active {
    color: ${setRgba('anchorOn')} !important;
    text-decoration: underline;
    cursor: pointer;
  }

  svg {
    display: inline-block;
    box-sizing: border-box;
  }

  svg.sharpie {
    position: relative;
    path {
      opacity: 0;
      fill: none;
      stroke: ${setRgba(false)};
      stroke-width: 6;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
  }
`

export default GlobalStyles
