import { createContext } from 'react'

const defaultState = {
  x: null,
  y: null,
  pageX: null,
  pageY: null,
  clientX: null,
  clientY: null,
  screenX: null,
  screenY: null,
  elementWidth: null,
  elementHeight: null,
  isOver: false,
  isDown: false,
  isTouch: false,
}

const MouseContext = createContext(defaultState)

export default MouseContext
