import styled from 'styled-components'

import { media, setRgba } from '@styles'
import WWanimHRSmall from '@raster/WWblacklodge-upward-scrolling-anim-small.gif'
import WWanimHR from '@raster/WWblacklodge-upward-scrolling-anim.gif'

export const WWdiv = styled.div`
  background-size: 33px 14px;
  background: url("${WWanimHRSmall}");
  height: 14px;
  margin: 42px auto;
  margin-bottom: 8px;
  mask-image: linear-gradient(90deg, ${setRgba(false, 0)} 0%, ${setRgba(false, 1)} 12%, ${setRgba(false, 1)} 88%, ${setRgba(false, 0)}100%);
  width: 88%;
  ${media.minWidth.mobile`
    background: url("${WWanimHR}");
    background-size: 54px 23px;
    height: 23px;
    margin-bottom: 16px;
  `}
  ${media.minWidth.tablet`
    margin-bottom: 24px;
  `}
  ${media.minWidth.desktop`
    margin-bottom: 76px;
  `}
  &.pre-bc,
  &.post-bc {
    width: 100%;
    margin-top: 0;
    mask-image: none;
  }
  &.pre-bc {
    margin: 4px 0 0 0;
    ${media.minWidth.mobile`
      margin: 0;
    `}
  }
  &.post-bc {
    ${media.minWidth.desktop`
      margin-bottom: 24px;
    `}
  }
  &.post-wwidgets {
    margin-bottom: 42px;
    margin-top: -400px;
    ${media.minWidth.mobile`
      margin-bottom: 32px;
      margin-top: -64px;
    `}
    ${media.minWidth.desktop`
      margin-bottom: 76px;
      margin-top: 76px;
    `}
  }
  &.wafer-thin {
    box-sizing: content-box;
    background: white;
    height: 2px; /* really 3 */
    border-bottom: solid 3px black;
    margin: 32px auto 42px;
  }
  a {
    display: block;
    width: 100%;
  }
  img {
    display: block;
    width: 100%;
  }
  body#test & {
    opacity: 0;
  }
`
