import React from 'react'

import WWHR from '@components/html/ww-hr'
import { CCContainer } from './styled'
import CcBySaAss from '@vector/cc-by-sa.svg'

const CreativeCommonsAttribution = () => {
  return (
    <>
      <WWHR className='wafer-thin' />
      <CCContainer>
        <a rel='license'
          href='https://creativecommons.org/licenses/by-sa/4.0/'
          target='_blank'
        >
          <img alt='Creative Commons License'
            src={ CcBySaAss }
          />
        </a>
        <br />
        <span { ...{ 'xmlns:dct': 'https://purl.org/dc/terms/' } }
          href='https://purl.org/dc/dcmitype/Sound'
          property='dct:title'
          rel='dct:type'
        >Days of Yore</span> by <a { ...{ 'xmlns:cc': 'https://creativecommons.org/ns#' } }
          href='https://wrongwindows.rocks'
          property='cc:attributionName'
          rel='cc:attributionURL'
        >The Wrong Windows</a> is licensed under a <a rel='license'
          href='https://creativecommons.org/licenses/by-sa/4.0/'
          target='_blank'
        >Creative Commons Attribution-ShareAlike 4.0 International License</a>.
      </CCContainer>
    </>
  )
}

export default CreativeCommonsAttribution
