import styled, { css } from 'styled-components'

export const screenSizes = {
  mobile: 576,
  tablet: 768,
  desktop: 992,
  large: 1024,
  xLarge: 1220,
}

export const mediaSizes = (edge = 'min', dimension = 'width', breakPoints = screenSizes) => Object.keys(breakPoints).reduce((acc, threshold) => {
  acc[threshold] = (...args) => css`
    @media (${edge}-${dimension}: ${breakPoints[threshold] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const media = {
  minWidth: mediaSizes(),
  maxWidth: mediaSizes('max', 'width'),
  minHeight: mediaSizes('min', 'height'),
  maxHeight: mediaSizes('max', 'height'),
}

export const setRgba = (rgb = [ 0, 0, 0 ], a = 1) => {
  let rgbs = null
  if (typeof rgb === 'boolean') {
    rgbs = rgb ? [ 255, 255, 255 ] : [ 0, 0, 0 ]
  } else if (typeof rgb === 'string') {
    if (rgb.indexOf('#') === 0) {
      const hex = rgb.substring(1)
      const hexes = []
      if (hex.length === 3) {
        for (let h = 0; h < 3; h++) {
          hexes.push(`${hex[h]}${hex[h]}`)
        }
      } else if (hex.length === 6) {
        for (let h = 0; h < 6; h += 2) {
          hexes.push(`${hex[h]}${hex[h + 1]}`)
        }
      }
      if (hexes.length === 3) {
        rgbs = []
        hexes.forEach(x => {
          rgbs.push(parseInt(x, 16))
        })
      }
    } else {
      // eslint-disable-next-line default-case
      switch (rgb) {
        case 'siteWhite':
          rgbs = [ 255, 255, 255 ]
          break
        case 'themePrimeGray':
        case 'themePrimeGrey':
          rgbs = [ 118, 122, 131 ]
          break
        case 'themeDarkGray':
        case 'themeDarkGrey':
          rgbs = [ 92, 95, 102 ]
          break
        case 'themeDarkerGray':
        case 'themeDarkerGrey':
          rgbs = [ 76, 78, 84 ]
          break
        case 'themeDarkestGray':
        case 'themeDarkestGrey':
          rgbs = [ 46, 47, 51 ]
          break
        case 'themeLightGray':
        case 'themeLightGrey':
          rgbs = [ 147, 152, 163 ]
          break
        case 'themeLighterGray':
        case 'themeLighterGrey':
          rgbs = [ 174, 180, 194 ]
          break
        case 'themeLightestGray':
        case 'themeLightestGrey':
          rgbs = [ 223, 230, 247 ]
          break
        case 'anchorOff':
          rgbs = [ 0, 255, 255 ]
          break
        case 'anchorOn':
          rgbs = [ 102, 255, 255 ]
          break
        case 'oppositeOrange':
          rgbs = [ 255, 0, 0 ]
          break
        case 'soundCloudOrangeDark':
          rgbs = [ 248, 49, 14 ]
          break
        case 'soundCloudOrangeLite':
          rgbs = [ 247, 152, 16 ]
          break
        case 'manillaGorilla':
          rgbs = [ 248, 245, 225 ]
          break
        case 'siteBlack':
          rgbs = [ 0, 0, 0 ]
          break
        case 'red':
          rgbs = [ 255, 0, 0 ]
          break
      }
    }
  } else {
    if (Array.isArray(rgb)) rgbs = rgb
  }
  if (rgbs && Array.isArray(rgbs)) {
    // console.log({ rgb: rgb, rgbs: rgbs, rgbsJoined: rgbs.join(', ') })
    rgb = rgbs.join(', ')
    return `rgba(${rgb}, ${a})`
  } else {
    // console.log({ rgb: rgb, rgbs: rgbs })
    return rgb
  }
}

export const shapedAura = (color = setRgba('siteWhite'), size = 10, shiftX = 0, shiftY = 0) => css`
  filter: drop-shadow(${shiftX}px ${shiftY}px ${size}px ${color});
`

export const BrIfTablet = styled.br`
  display: none;
  ${media.minWidth.tablet`
    display: inline-block;
  `}
`
export const BrIfLarge = styled.br`
  display: none;
  ${media.minWidth.large`
    display: inline-block;
  `}
`

export const emboldenedTitle = ({ sizeUnits = 'px', fontSize = 42, shadowSize = 1 } = {}) => css`
  font-size: ${fontSize}${sizeUnits};
  font-weight: 900;
  color: white;
  text-shadow: ${shadowSize}${sizeUnits} ${shadowSize}${sizeUnits} 0 #000,
    -${shadowSize}${sizeUnits} -${shadowSize}${sizeUnits} 0 #000,
    ${shadowSize}${sizeUnits} -${shadowSize}${sizeUnits} 0 #000,
    -${shadowSize}${sizeUnits} ${shadowSize}${sizeUnits} 0 #000,
    0 ${shadowSize}${sizeUnits} 0 #000,
    ${shadowSize}${sizeUnits} 0 0 #000,
    0 -${shadowSize}${sizeUnits} 0 #000,
    -${shadowSize}${sizeUnits} 0 0 #000,
    ${shadowSize * 2}${sizeUnits} ${shadowSize * 2}${sizeUnits} 0 #000,
    -${shadowSize * 2}${sizeUnits} -${shadowSize * 2}${sizeUnits} 0 #000,
    ${shadowSize * 2}${sizeUnits} -${shadowSize * 2}${sizeUnits} 0 #000,
    -${shadowSize * 2}${sizeUnits} ${shadowSize * 2}${sizeUnits} 0 #000,
    0 ${shadowSize * 2}${sizeUnits} 0 #000,
    ${shadowSize * 2}${sizeUnits} 0px 0 #000,
    0 -${shadowSize * 2}${sizeUnits} 0 #000,
    -${shadowSize * 2}${sizeUnits} 0 0 #000,
    ${shadowSize * 3}${sizeUnits} ${shadowSize * 3}${sizeUnits} 0 #000,
    -${shadowSize * 3}${sizeUnits} -${shadowSize * 3}${sizeUnits} 0 #000,
    ${shadowSize * 3}${sizeUnits} -${shadowSize * 3}${sizeUnits} 0 #000,
    -${shadowSize * 3}${sizeUnits} ${shadowSize * 3}${sizeUnits} 0 #000,
    0 ${shadowSize * 3}${sizeUnits} 0 #000,
    ${shadowSize * 3}${sizeUnits} 0 0 #000,
    0 -${shadowSize * 3}${sizeUnits} 0 #000,
    -${shadowSize * 3}${sizeUnits} 0 0 #000,
    ${shadowSize * 4}${sizeUnits} ${shadowSize * 4}${sizeUnits} 0 #000,
    -${shadowSize * 4}${sizeUnits} -${shadowSize * 4}${sizeUnits} 0 #000,
    ${shadowSize * 4}${sizeUnits} -${shadowSize * 4}${sizeUnits} 0 #000,
    -${shadowSize * 4}${sizeUnits} ${shadowSize * 4}${sizeUnits} 0 #000,
    0 ${shadowSize * 4}${sizeUnits} 0 #000,
    ${shadowSize * 4}${sizeUnits} 0 0 #000,
    0 -${shadowSize * 4}${sizeUnits} 0 #000,
    -${shadowSize * 4}${sizeUnits} 0 0 #000,
    ${shadowSize * 5}${sizeUnits} ${shadowSize * 5}${sizeUnits} 0 #000,
    -${shadowSize * 5}${sizeUnits} -${shadowSize * 5}${sizeUnits} 0 #000,
    ${shadowSize * 5}${sizeUnits} -${shadowSize * 5}${sizeUnits} 0 #000,
    -${shadowSize * 5}${sizeUnits} ${shadowSize * 5}${sizeUnits} 0 #000,
    0 ${shadowSize * 5}${sizeUnits} 0 #000,
    ${shadowSize * 5}${sizeUnits} 0 0 #000,
    0 -${shadowSize * 5}${sizeUnits} 0 #000,
    -${shadowSize * 5}${sizeUnits} 0 0 #000;
  &.impact {
    font-family: Impact, Haettenschweiler, "Franklin Gothic Bold", Charcoal, "Helvetica Inserat", "Bitstream Vera Sans Bold", "Arial Black", "sans serif";
  }
`

export const EmH1 = styled.h1`
  ${emboldenedTitle()}
  max-width: 88vw;
  margin-left: auto;
  margin-right: auto;
  &.taller {
    line-height: 150%;
  }
`

export const EmH2 = styled.h2`
  ${emboldenedTitle()}
  font-size: 23px;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
`
