import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import { imageUrlFromAssetSource } from '@utils/image'
// import FaviconAss from '@raster/WW_icon.png'

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: {eq: "siteSettings"}) {
      title
      description
      keywords
      author {
        name
      }
      canonical
      icon {
        asset {
          _id
        }
      }
      openGraphImage {
        asset {
          _id
        }
      }
      _createdAt
      _updatedAt
    }
  }
`

function PageHeaderInvisible ({
  className,
  description,
  pageId,
  lang = 'en',
  keywords = [],
  meta = [],
  title,
}) {
  return (
    <StaticQuery query={ detailsQuery }
      render={ data => {
        if (!data.site) {
          throw new Error(
            'PageHeaderInvisible ERROR: Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data',
          )
        }
        const metaDescription = description || (data.site && data.site.description) || ''
        const siteAuthor = (data.site && data.site.author && data.site.author.name) || ''
        const siteTitle = (data.site && data.site.title) || ''
        const bodyAttributes = {
          id: pageId || false,
          class: className || '',
        }
        const openGraphImageURL = imageUrlFromAssetSource(data.site.openGraphImage)
        return (
          <Helmet bodyAttributes={ bodyAttributes }
            htmlAttributes={ { lang } }
            defaultTitle={ siteTitle }
            titleTemplate={ title === siteTitle ? '%s' : `%s | ${siteTitle}` }
            meta={ [
              {
                content: metaDescription,
                name: 'description',
              },
              {
                content: title,
                property: 'og:title',
              },
              {
                content: metaDescription,
                property: 'og:description',
              },
              {
                content: 'website',
                property: 'og:type',
              },
              {
                content: data.site.canonical,
                property: 'og:url',
              },
              {
                content: openGraphImageURL,
                property: 'og:image',
              },
              {
                content: siteTitle,
                property: 'og:site_name',
              },
              {
                content: data.site._createdAt,
                property: 'article:published_time',
              },
              {
                content: data.site._updatedAt,
                property: 'article:modified_time',
              },
              {
                content: '348503311971464',
                property: 'fb:admins',
              },
              {
                content: 'summary',
                name: 'twitter:card',
              },
              {
                content: '@thewrongwindows',
                name: 'twitter:site',
              },
              {
                content: siteAuthor,
                name: 'twitter:creator',
              },
              {
                content: title,
                name: 'twitter:title',
              },
              {
                content: metaDescription,
                name: 'twitter:description',
              },
              {
                content: openGraphImageURL,
                name: 'twitter:image:src',
              },
              {
                href: data.site.canonical,
                property: 'link',
                rel: 'canonical',
              },
              {
                href: data.site.canonical,
                property: 'base',
                target: '_self',
              },
              // ICONS ARE NOW HANDLED BY gatsby-plugin-manifest
              // {
              //   href: '/etc/favicon.ico',
              //   property: 'link',
              //   rel: 'shortcut icon',
              //   type: 'image/x-icon',
              // },
              // {
              //   href: FaviconAss,
              //   property: 'link',
              //   rel: 'shortcut icon',
              //   type: 'image/png',
              // },
              // {
              //   href: FaviconAss,
              //   property: 'link',
              //   rel: 'apple-touch-icon',
              //   type: 'image/png',
              // },
              // THIS IS THE COMODO/SECTIGO SHIT THAT I CAN'T GET WORK, SO FUCK THAT TRUSTSEAL, RIGHT?
              // {
              //   'property': 'script',
              //   'type': 'text/javascript',
              //   'src': `https://${window.location.protocol === 'https:' ? `secure.trust-provider` : `www.trustlogo`}.com/trustlogo/javascript/trustlogo.js`,
              // },
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                    name: 'keywords',
                    content: keywords.join(', '),
                  }
                  : [],
              )
              .concat(meta) }
          >
            { /* <script type='text/javascript'
              src={ `https://${window.location.protocol === 'https:' ? `secure.trust-provider` : `www.trustlogo`}.com/trustlogo/javascript/trustlogo.js` }
            /> */ }
          </Helmet>
        )
      } }
    />
  )
}

export default PageHeaderInvisible
