import React from 'react'

import Subscribe from './subscribe'
import CreativeCommonsAttribution from './creative-commons'
import RevolvermapsGlobe from './revolvermaps-globe'

export default function PreFooterVisible ({ render = true }) {
  return render ? (
    <main>
      <section id='subscribe'>
        <Subscribe />
      </section>
      <section id='globe'>
        <RevolvermapsGlobe />
      </section>
      <section id='cc'>
        <CreativeCommonsAttribution />
      </section>
      <br />
    </main>
  ) : ``
}
