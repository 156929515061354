import React, { useEffect, useState } from 'react'
import { loadJavaScriptInto } from '@utils'

const PositiveSSLTrustSeal = () => {
  const [ trustSealLoaded, setTrustSealLoaded ] = useState('')

  useEffect(() => {
    loadJavaScriptInto('trusted', null, `https://${window.location.protocol === 'https:' ? 'secure.trust-provider' : 'www.trustlogo'}.com/trustlogo/javascript/trustlogo.js`, () => { setTrustSealLoaded(<script>TrustLogo('https://www.positivessl.com/images/seals/positivessl_trust_seal_lg_222x54.png', 'POSDV', 'none')</script>) })
  }, []) // RUN ONCE ON LOAD

  return (
    <div id='trusted'
      className='trusted'
    >
      { trustSealLoaded }
      <a href='https://www.positivessl.com/'
        id='comodoTL'
        target='_blank'
      >Positive SSL</a>
    </div>
  )
}

export default PositiveSSLTrustSeal
