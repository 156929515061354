import styled from 'styled-components'

import { media, setRgba } from '@styles'

export const CCContainer = styled.div`
  display: inline-block;
  max-width: 88vw;
  margin-left: auto;
  margin-right: auto;
  img {
    transform-origin: 50% 100%;
    width: 88px;
    height: 31px;
    transition: all 0.15s ease-in-out;
  }
  &:hover img {
    transform: scale(1.5);
    /* border: solid 1px black; */
    box-shadow: 0 0 4px ${setRgba('anchorOn')};
  }
`

export const GlobeWrapper = styled.div`
  transform: scale(0.72);
  margin: -48px 0 -42px -2vw;
  ${media.minWidth.mobile`
    margin: 0;
    transform: scale(1);
  `}
`
