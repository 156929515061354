import styled, { css } from 'styled-components'
import { clearFix } from 'polished'

import { emboldenedTitle, media, setRgba } from '@styles'
import GLink from '@components/html/g-link'
import WWpinchedIconStrokedAss from '@vector/WW-pinched-icon-stroked.svg'

const navLinkAnchorMinWidth = label => {
  const charLen = label.length
  return `width: ${(charLen * 12).toString()}px;`
}

// const narrowYourNavz = (fontyPool) => media.maxWidth.desktop`
//   transform: scaleY(1.33);
//   ${fontyPool ? 'font-size: 12px;' : ''}
// `

const notHome = (title) => {
  return css`
    ${navLinkAnchorMinWidth(title)}
    /* ${narrowYourNavz(true)} */
  `
}

const NavBar = styled.nav.attrs(props => ({
  className: props.className || 'clearUnfix',
  role: props.role || 'navigation',
  'aria-label': props['aria-label'] || 'Main Menu',
}))`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 70px;
  box-sizing: content-box;
  margin: 0;
  padding: 0;
`

export const NavBarWrapper = styled(NavBar)`
  background: ${setRgba('themeLightGray')};
  border-bottom: 5px solid ${setRgba('siteWhite')}; /* it's really 6 pixels, WHY MUST I FIGHT WITH INTEGERS */
  header.hover & {
    transition: all 0.5s ease-in-out 0.15s;
    background: ${setRgba('themeLightGray', 0.69)};
    border-bottom: 6px solid ${setRgba('siteWhite', 0.69)};
    width: 250vw;
    mask-image: linear-gradient(45deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 60%);
    mask-size: cover;
  }
  header.hover.headerHidden & {
    transition: all 0.5s ease-in-out;
    left: -150vw;
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    max-height: 3px;
    border-bottom: solid 3px black;
    bottom: -5px;
    left: 0;
    z-index: 2;
  }
`
export const NavLinksList = styled.ul.attrs(props => ({
  role: props.role || 'menubar',
  'aria-hidden': props['aria-hidden'] || false,
}))`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  height: 70px;
  width: calc(99.16vw - 52px);
  margin: 0;
  margin-left: calc(52px + 4vw);
  margin-top: -1px;
  padding: 0;
  justify-content: space-around;
  text-align: center;
  transition: width 0.15s ease-in-out;
  ${media.minWidth.mobile`
    margin-left: calc(64px + 0.84vw);
  `}
`

export const NavLinkItem = styled.li.attrs(props => ({
  role: props.role || 'menuitem',
  'aria-haspopup': props['aria-haspopup'] || false,
}))`
  border-radius: 50%;
  display: inline-block;
  height: 32px;
  list-style-type: none;
  margin: 6px -2vw;
  position: relative;
  text-align: center;
  transform-origin: 50% 50%;
  transform: rotate(-42deg) scaleY(1.33);
  transition: transform 0.15s ease-in-out;
  &:nth-of-type(3) {
    transform: translateX(-11px) rotate(-42deg) scaleY(1.33);
  }
  &:nth-of-type(4) {
    transform: translateX(-30px) rotate(-42deg) scaleY(1.33);
  }
  &.hovered,
  &:hover,
  &:active {
    transform: rotate(0) scaleY(1.33);
    &:nth-of-type(3) {
      transform: translateX(-11px) rotate(0) scaleY(1.33);
    }
    &:nth-of-type(4) {
      transform: translateX(-30px) rotate(0) scaleY(1.33);
    }
  }
  ${media.minWidth.mobile`
    margin: 0;
    transform: rotate(-12deg);
    &:nth-of-type(3),
    &:nth-of-type(4) {
      transform: rotate(-12deg);
    }
    &.hovered,
    &:hover,
    &:active {
      transform: rotate(0);
      &:nth-of-type(3),
      &:nth-of-type(4) {
        transform: rotate(0);
      }
    }
  `}
`
export const HomeLinkItem = styled(NavLinkItem)`
  border-radius: 0;
  display: block;
  height: 76px;
  left: 0;
  margin: 0 calc(12px + 0.42vw);
  position: absolute;
  top: 0;
  transform: none !important;
  width: 40px;
  header.hover & {
    position: fixed;
  }
`

export const NavLinkAnchor = styled(GLink).attrs(props => ({
  'aria-label': props['aria-label'] || '',
  href: props.href || undefined,
  tabIndex: props.tabIndex || '0',
  target: props.target || '_self',
  title: props.title || '',
  to: props.to || props.href || undefined,
}))`
  ${props => props.href !== '/' ? notHome(props.title) : ''}
  ${clearFix()}
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 32px;
  letter-spacing: 0.09em;
  max-height: 32px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  width: auto;

  span {
    ${emboldenedTitle({ fontSize: 9 })}
    border-radius: 50%;
    display: block;
    height: 32px;
    line-height: 32px;
    max-height: 32px;
    text-align: center;
    width: auto;
    ${media.minWidth.mobile`
      ${emboldenedTitle({ fontSize: 18 })}
      margin: 0 12px;
      transform: none;
    `}
    &.l337 {
      color: ${setRgba('siteWhite')};
    }
    &.legible {
      color: ${setRgba('anchorOff')};
      left: 0;
      opacity: 0;
      position: absolute;
      text-decoration: underline;
      top: 0;
      transition: opacity 0.15s ease-in-out;
      .shopArrow {
        position: absolute;
        display: block;
        z-index: -1;
        top: 0;
        transform: translate(-2px, 14px) scale(0.64, 0.5);
        &:first-of-type {
          transform: translate(-18px, 14px) scale(0.64, 0.5);
        }
        &:last-of-type {
          transform: translate(16px, 14px) scale(0.64, 0.5);
        }
        ${media.minWidth.tablet`
          transform: translate(-2px, 8px) scale(0.4);
          &:first-of-type {
            transform: translate(-48px, 9px) rotate(50deg) scale(0.46);
          }
          &:last-of-type {
            transform: translate(48px, 9px) rotate(-50deg) scale(0.46);
          }
        `}
      }
    }
  }
  &:hover,
  &:active,
  .hovered & {
    .legible {
      opacity: 1;
    }
  }
`

export const HomeLinkAnchor = styled(NavLinkAnchor).attrs(props => ({
  className: props.className || '',
}))`
  border-radius: 0;
  display: block;
  height: 100%;
  margin-top: 9px;
  position: absolute;
  transform-origin: 50% 50%;
  transition: all 0.25s ease-in-out;
  width: 100%;
  &::after {
    background-image: url('${WWpinchedIconStrokedAss}');
    background-size: cover;
    content: '';
    filter: blur(2px);
    height: 64px;
    left: -3.5px;
    opacity: 0.23;
    position: absolute;
    top: -9px;
    transition: all 0.25s ease-in-out;
    width: 48px;
    z-index: -1;
    header.hover.headerHidden & {
      opacity: 0.64;
    }
  }
  header.hover.headerHidden & {
    opacity: 0.42;
  }
  &:hover,
  &:active {
    transform: scale(1.23) translateY(-2px);
  }
`

export const SubNavBarWrapper = styled(NavBar)`
  position: fixed;
  top: 75px;
  transform: scale(0);
  transform-origin: calc(42vw + 4%) 0; /* So many magic numbers */
  transition: all 0.15s ease-in-out;
  width: 212px;
  z-index: 30000;
  &.open {
    transform: scale(1);
  }
  ${media.minWidth.mobile`
    transform-origin: calc(39.25vw + 4%) 0;
  `}
  ${media.minWidth.tablet`
    transform-origin: calc(38vw + 4%) 0;
    width: 600px;
  `}
  /* trapezoid trickery */
  .trap {
    border-left: 23px solid transparent;
    border-right: 23px solid transparent;
    border-top: 100px solid ${setRgba('themeLighterGray')};
    height: 100px;
    left: 50%;
    margin-left: -63.25%;
    position: absolute;
    transform: translateY(calc(-100% - 2.4px));
    width: 212px;
    ${media.minWidth.tablet`
      border-top: 70px solid ${setRgba('themeLighterGray')};
      height: 70px;
      margin-left: -54%;
      transform: translateY(calc(-100% - 2px));
      width: 600px;
    `}
    &.trapWhite {
      border-top: 103px solid white;
      margin-left: 0;
      position: relative;
      transform: translate(-50%, calc(-100% - 2.6px));
      width: 213.5px;
      ${media.minWidth.tablet`
        border-top: 73px solid white;
        transform: translate(-50%, calc(-100% - 3px));
        width: 601.5px;
      `}
    }
    &.trapBlack,
    &.trapShadow {
      border-top: 106px solid black;
      left: calc(42vw + 4%);
      margin-left: 0;
      position: relative;
      transform: translateX(-50%);
      width: 215px;
      ${media.minWidth.mobile`
        left: calc(39.25vw + 4%);
      `}
      ${media.minWidth.tablet`
        border-top: 76px solid black;
        left: calc(38vw + 4%);
        width: 603px;
      `}
    }
    &.trapBlack {
      top: -106px;
      ${media.minWidth.tablet`
        top: -76px;
      `}
    }
    &.trapShadow {
      filter: blur(3px);
      top: 1px;
      opacity: 0.5;
    }
  }
`

export const SubNavLinksList = styled(NavLinksList).attrs(props => ({
  className: props.className || 'sub',
}))`
  flex-direction: column;
  min-width: 132px;
  ${media.minWidth.tablet`
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 600px;
  `}
`

export const SubNavLinkItem = styled(NavLinkItem)`
  margin-top: -10px;
  transform: translateY(-32px) scaleY(1.33) !important;
  ${media.minWidth.mobile`
    margin-top: -4px;
    transform: translateY(-38px) scaleY(1.33) !important;
  `}
  ${media.minWidth.tablet`
    height: 64px;
    margin-top: -10px;
    top: -26px;
    transform: none !important;
  `}
`

export const SubNavLinkAnchor = styled(NavLinkAnchor).attrs(props => ({
  href: props.href || '#',
  tabIndex: props.tabIndex || '0',
  target: props.target || '_self',
  'aria-label': props['aria-label'] || '',
  title: props.title || '',
}))`
  &.mobileAndSmaller {
    ${media.minWidth.tablet`
      display: none;
    `}
  }
  &.tabletAndLarger {
    ${media.maxWidth.tablet`
      display: none;
    `}
  }
  span {
    line-height: 18px;
    ${media.minWidth.mobile`
      ${emboldenedTitle({ fontSize: 9 })}
    `}
    ${media.minWidth.tablet`
      ${emboldenedTitle({ fontSize: 18 })}
      line-height: 28px;
    `}
  }
`
