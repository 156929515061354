import React, { useEffect, useRef, useState } from 'react'
import { useWindowSize } from '@react-hook/window-size/throttled'
import useMouse from '@react-hook/mouse-position'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import WindowContext from '@context/window'
import MouseContext from '@context/mouse'
import GlobalStyles from '@styles/global'
import PageHeaderInvisible from './page-header-invisible'
import PageHeaderVisible from './page-header-visible'
import PreFooterVisible from './pre-footer-visible'
import PageFooterVisible from './page-footer-visible'
import PageFooterInvisible from './page-footer-invisible'

const Page = ({
  className = false,
  id = false,
  title = '',
  headerVisible = false,
  headerRevealMethod = 'scroll',
  preFooterVisible = false,
  footerVisible = false,
  children,
}) => {
  const mouseOverMe = useRef(null)
  const [ w, setW ] = useState({})
  const [ m, setM ] = useState({})
  const [ headerReveal, setHeaderReveal ] = useState(headerRevealMethod === 'scroll')
  const navBarHeight = 76
  let mm, ww, wh

  if (typeof window !== 'undefined') {
    mm = useMouse(mouseOverMe, {
      fps: 30,
      enterDelay: 75,
      leaveDelay: 75,
    })
    const [ uw, uh ] = useWindowSize({ fps: 24 })
    ww = uw
    wh = uh
  }

  if (headerVisible && headerRevealMethod === 'scroll') {
    useScrollPosition(({ prevPos, currPos }) => {
      // console.log({ prevPos, currPos })
      if (currPos.y < 0 &&
        currPos.y < prevPos.y &&
        Math.abs(currPos.y) > navBarHeight
      ) setHeaderReveal(false)
      else if (
        currPos.y > prevPos.y
      ) setHeaderReveal(true)
    }, [ headerReveal, navBarHeight ])
  }

  useEffect(() => {
    if (headerVisible && headerRevealMethod === 'hover') {
      if (mm.y && mm.y <= 76) {
        if (!headerReveal) setHeaderReveal(true)
      } else {
        if (headerReveal) setHeaderReveal(false)
      }
    }
    setM(mm)
    // return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ headerRevealMethod, mm ]) // RUN ON LOAD AND WHEN VARS UPDATE

  useEffect(() => {
    setW({
      h: wh,
      w: ww,
      cx: ww / 2,
      cy: wh / 2,
    })
    // return () => {}
  }, [ ww, wh ]) // RUN ON LOAD AND WHEN VARS UPDATE

  return (
    <WindowContext.Provider value={ w }>
      <MouseContext.Provider value={ m }>
        <GlobalStyles />
        <article ref={ mouseOverMe }
          className='page'
        >
          <PageHeaderInvisible pageId={ id }
            className={ className }
            title={ title }
          />
          <PageHeaderVisible render={ headerVisible }
            reveal={ headerReveal }
            revealMethod={ headerRevealMethod }
          />
          <main>{ children }</main>
          <PreFooterVisible render={ preFooterVisible } />
          <PageFooterVisible render={ footerVisible } />
          <PageFooterInvisible />
        </article>
      </MouseContext.Provider>
    </WindowContext.Provider>
  )
}

export default Page
