/* eslint-disable object-property-newline, no-unused-expressions */
import { css, keyframes } from 'styled-components'

import { setRgba } from '@styles'

const keyframesCSS = {}
const animate = {}
const animationCSS = ({
  animation,
  duration = 1,
  timing = 'linear',
  delay = 0,
  iterations = 'infinite',
  direction = 'normal',
  fillMode = 'forwards',
} = {}) => css`
  animation: ${animation} ${duration}s ${timing} ${delay}s ${iterations} ${direction} ${fillMode};
`

keyframesCSS.bounceInPlace = ({ height = '16%' } = {}) => keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-${height});
  }
`
animate.bounceInPlace = ({
  height = '16%',
  duration = 0.23,
  timing = 'ease-out',
  delay = 0,
  iterations = 'infinite',
  direction = 'alternate',
  fillMode = 'forwards',
} = {}) => animationCSS({
  animation: keyframesCSS.bounceInPlace({ height }),
  duration, timing, delay, iterations, direction, fillMode,
})

keyframesCSS.fadeIn = ({ oFrom = 0, oTo = 1 } = {}) => keyframes`
  from {
    opacity: ${oFrom};
  }
  to {
    opacity: ${oTo};
  }
`
animate.fadeIn = ({
  oFrom = 0,
  oTo = 1,
  duration = 1,
  timing = 'ease-out',
  delay = 0,
  iterations = 1,
  direction = 'normal',
  fillMode = 'forwards',
} = {}) => animationCSS({
  animation: keyframesCSS.fadeIn({ oFrom, oTo }),
  duration, timing, delay, iterations, direction, fillMode,
}),

keyframesCSS.fadeInAndLift = () => keyframes`
  0% {
    top: -10px;
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: -10px;
    opacity: 1;
  }
  100% {
    top: calc(-100% - 10px);
    opacity: 1;
  }
`
animate.fadeInAndLift = ({
  duration = 10,
  timing = 'ease-in',
  delay = 0,
  iterations = 1,
  direction = 'normal',
  fillMode = 'forwards',
} = {}) => animationCSS({
  animation: keyframesCSS.fadeInAndLift(),
  duration, timing, delay, iterations, direction, fillMode,
})

keyframesCSS.fadeOut = ({ oFrom = 0, oTo = 1 } = {}) => keyframes`
  from {
    opacity: ${oFrom};
  }
  to {
    opacity: ${oTo};
  }
`
animate.fadeOut = ({
  oFrom = 1,
  oTo = 0,
  duration = 1,
  timing = 'ease-out',
  delay = 0,
  iterations = 1,
  direction = 'normal',
  fillMode = 'forwards',
} = {}) => animationCSS({
  animation: keyframesCSS.fadeOut({ oFrom, oTo }),
  duration, timing, delay, iterations, direction, fillMode,
})

keyframesCSS.blinkInAndOut = () => keyframes`
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  3% {
    opacity: 0;
  }
`
animate.blinkInAndOut = ({
  duration = 116,
  timing = 'linear',
  delay = 25,
  iterations = 'infinite',
  direction = 'normal',
  fillMode = 'forwards',
} = {}) => animationCSS({
  animation: keyframesCSS.blinkInAndOut(),
  duration, timing, delay, iterations, direction, fillMode,
})

keyframesCSS.floatOnFrom = ({ floFrom = 'topLeft' } = {}) => {
  switch (floFrom) {
    case 'topLeft':
      floFrom = {
        t: '-100%',
        l: '-100%',
      }
      break
    case 'topRight':
      floFrom = {
        t: '-100%',
        l: '100%',
      }
      break
    case 'bottomLeft':
      floFrom = {
        t: '100%',
        l: '-100%',
      }
      break
    case 'bottomRight':
      floFrom = {
        t: '100%',
        l: '100%',
      }
      break
  }
  return keyframes`
    from {
      opacity: 0;
      margin-top: ${floFrom.t};
      margin-left: ${floFrom.l};
    }
  `
}
animate.floatOnFrom = ({
  floFrom = 'topLeft',
  duration = 1,
  timing = 'ease-out',
  delay = 0,
  iterations = 1,
  direction = 'normal',
  fillMode = 'forwards',
} = {}) => animationCSS({
  animation: keyframesCSS.floatOnFrom({ floFrom }),
  duration, timing, delay, iterations, direction, fillMode,
})

// keyframesCSS.floorGround = () => keyframes`
//   to {
//     opacity: 1;
//   }
// `
// animate.floorGround = ({
//   duration = 6.4,
//   timing = 'ease-in',
//   delay = 0,
//   iterations = 1,
//   direction = 'normal',
//   fillMode = 'forwards',
// } = {}) => animationCSS({
//   animation: keyframesCSS.floorGround(),
//   duration, timing, delay, iterations, direction, fillMode,
// })

keyframesCSS.gradeButNotOnACurve = ({ yFrom = 0, yTo = 1 } = {}) => keyframes`
  0%, 100% {
    top: ${yFrom}px;
  }
  50% {
    top: ${yTo}px;
  }
`
animate.gradeButNotOnACurve = ({
  yFrom = 0,
  yTo = 50,
  duration = 1,
  timing = 'ease-out',
  delay = 0,
  iterations = 'infinite',
  direction = 'normal',
  fillMode = 'forwards',
} = {}) => animationCSS({
  animation: keyframesCSS.gradeButNotOnACurve({ yFrom, yTo }),
  duration, timing, delay, iterations, direction, fillMode,
}),

keyframesCSS.pulseAura = ({ color = setRgba('oppositeOrange'), maxSize = '0.70588235vw' } = {}) => keyframes`
  from {
    text-shadow: 0 0 0.11764706vw ${color};
  }
  to {
    text-shadow: 0 0 ${maxSize} ${color};
  }
`
animate.pulseAura = ({
  color = setRgba('oppositeOrange'),
  maxSize = '0.70588235vw',
  duration = 1,
  timing = 'ease-out',
  delay = 0,
  iterations = 'infinite',
  direction = 'alternate',
  fillMode = 'forwards',
} = {}) => animationCSS({
  animation: keyframesCSS.pulseAura({ color, maxSize }),
  duration, timing, delay, iterations, direction, fillMode,
})

keyframesCSS.rockabye = ({ rockDeg = 28 }) => keyframes`
  from {
    transform: rotate(-${rockDeg}deg);
  }
  to {
    transform: rotate(${rockDeg}deg);
  }
`
animate.rockabye = ({
  rockDeg = 28,
  duration = 1,
  timing = 'ease-in-out',
  delay = 0,
  iterations = 'infinite',
  direction = 'alternate',
  fillMode = 'forwards',
} = {}) => animationCSS({
  animation: keyframesCSS.rockabye({ rockDeg }),
  duration, timing, delay, iterations, direction, fillMode,
})


keyframesCSS.slide = ({ axis = 'Y', sFrom, sTo } = {}) => {
  let kfcss = ''
  if (sFrom) {
    kfcss += `
      from {
        transform: translate${axis}(${sFrom}px);
      }
    `
  }
  if (sTo) {
    kfcss += `
      to {
        transform: translate${axis}(${sTo}px);
      }
    `
  }
  return (kfcss.length) ? keyframes`${kfcss}` : ''
}

keyframesCSS.translateInUnits = ({ axis = 'Y', units = 'px', tFrom = 0, tTo = 1 } = {}) => keyframes`
  from {
    transform: translate${axis}(${tFrom}${units});
  }
  to {
    transform: translate${axis}(${tTo}${units});
  }
`
animate.translateInUnits = ({
  axis = 'Y',
  units = 'px',
  tFrom = 0,
  tTo = -120,
  duration = 6,
  timing = 'linear',
  delay = 0,
  iterations = 1,
  direction = 'normal',
  fillMode = 'forwards',
} = {}) => animationCSS({
  animation: keyframesCSS.translateInUnits({ axis, units, tFrom, tTo }),
  duration, timing, delay, iterations, direction, fillMode,
})

keyframesCSS.spin = ({ axis = 'Z', startFromDeg = 0, spinToDeg = startFromDeg + 360 } = {}) => {
  switch (axis) {
    case 'X':
      return keyframes`
        from {
          transform: rotateX(${startFromDeg}deg);
        }
        to {
          transform: rotateX(${spinToDeg}deg);
        }
      `
    case 'Y':
      return keyframes`
        from {
          transform: rotateY(${startFromDeg}deg);
        }
        to {
          transform: rotateY(${spinToDeg}deg);
        }
      `
    case 'Z':
    default:
      return keyframes`
        from {
          transform: rotateZ(${startFromDeg}deg);
        }
        to {
          transform: rotateZ(${spinToDeg}deg);
        }
      `
  }
}
animate.spin = ({
  axis = 'Z',
  startFromDeg = 0,
  spinToDeg = startFromDeg + 360,
  duration = 1,
  timing = 'ease-in-out',
  delay = 0,
  iterations = '1',
  direction,
  fillMode = 'forwards',
} = {}) => {
  switch (direction) {
    case 'counter':
    case 'counterClockwise':
    case 'left':
      direction = 'reverse'
      break
    case 'clockwise':
    case 'right':
    default:
      direction = 'normal'
  }
  return animationCSS({
    animation: keyframesCSS.spin({ axis, startFromDeg, spinToDeg }),
    duration, timing, delay, iterations, direction, fillMode,
  })
}

// animate.digest = () => css`
//   animation: ${keyframesCSS.spin({ 'Z', 0 })} 0.25s ease-in infinite, ${keyframesCSS}.;
// `

keyframesCSS.dash = () => keyframes`
  0% {
    stroke-dashoffset: 1069260; // 100;
    stroke-width: 10.6926; // 0.001;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 445.525; // 0.042;
  }
`
animate.dash = ({
  duration = 1,
  timing = 'ease-in-out',
  delay = 0,
  iterations = '1',
  direction,
  fillMode = 'forwards',
} = {}) => {
  return animationCSS({
    animation: keyframesCSS.dash(),
    duration, timing, delay, iterations, direction, fillMode,
  })
}

keyframesCSS.unBlur = () => keyframes`
  to {
    filter: blur(0);
  }
`
animate.unBlur = ({
  duration = 1,
  timing = 'ease-in-out',
  delay = 0,
  iterations = '1',
  direction,
  fillMode = 'forwards',
} = {}) => {
  return animationCSS({
    animation: keyframesCSS.unBlur(),
    duration, timing, delay, iterations, direction, fillMode,
  })
}

keyframesCSS.pulse = ({ fromScale = 0.5, toScale = 1.5 } = {}) => keyframes`
  from {
    transform: scale(${fromScale}, ${fromScale});
  }
  to {
    transform: scale(${toScale}, ${toScale});
  }
`
animate.pulse = ({
  fromScale = 0.75,
  toScale = 1.25,
  duration = 1,
  timing = 'ease-out',
  delay = 0,
  iterations = '1',
  direction = 'alternate',
  fillMode = 'forwards',
} = {}) => {
  return animationCSS({
    animation: keyframesCSS.pulse({ fromScale, toScale }),
    duration, timing, delay, iterations, direction, fillMode,
  })
}

keyframesCSS.bgColorPulse = ({ fromColor = setRgba(false), toColor = setRgba(true) } = {}) => keyframes`
  0%,
  100% {
    background-color: ${fromColor};
  }
  50% {
    background-color: ${toColor};
  }
`
animate.bgColorPulse = ({
  /* eslint-disable-next-line array-bracket-newline, array-element-newline */
  fromColor = setRgba([ 243, 6, 3 ]),
  /* eslint-disable-next-line array-bracket-newline, array-element-newline */
  toColor = setRgba([ 241, 243, 2 ]),
  duration = 1,
  timing = 'ease-in-out',
  delay = 0,
  iterations = '1',
  direction = 'alternate',
  fillMode = 'forwards',
} = {}) => {
  return animationCSS({
    animation: keyframesCSS.bgColorPulse({ fromColor, toColor }),
    duration, timing, delay, iterations, direction, fillMode,
  })
}

keyframesCSS.jarp = () => keyframes`
  0%,
  100% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.05);
  }
  50% {
    transform: scale(1.025, 1);
  }
  75% {
    transform: scale(1, 1.05);
  }
`
animate.jarp = () => animationCSS({
  animation: keyframesCSS.jarp(),
  duration: 3,
  timing: 'linear',
  iterations: 'infinite',
})

keyframesCSS.exclaim = () => keyframes`
  0% {
    transform: scale(3, 0);
    opacity: 1;
    top: 42px;
  }
  5% {
    transform: scale(1, 1);
  }
  32% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    top: -100px;
    opacity: 0;
  }
`
animate.exclaim = () => animationCSS({
  animation: keyframesCSS.exclaim(),
  duration: 3,
  timing: 'ease-out',
  iterations: 1,
  fillMode: 'forwards',
})

keyframesCSS.andAwayWeGo = () => keyframes`
  0% {
    transform: rotate(0deg) scale(1, 1);
  }
  25% {
    transform: rotate(-30deg) scale(1, 1);
  }
  50% {
    transform: rotate(0deg) scale(1, 1);
  }
  100% {
    transform: rotate(1080deg) scale(0, 0);
  }
`
animate.andAwayWeGo = () => animationCSS({
  animation: keyframesCSS.andAwayWeGo(),
  duration: 1,
  timing: 'ease-in',
  delay: 1,
  fillMode: 'forwards',
})

keyframesCSS.andHereWeAre = () => keyframes`
  0% {
    transform: rotate(-1080deg) scale(0, 0);
  }
  100% {
    transform: rotate(0) scale(1, 1);
  }
`
animate.andHereWeAre = () => animationCSS({
  animation: keyframesCSS.andHereWeAre(),
  duration: 0.2,
  timing: 'ease-in',
  delay: 0.75,
  iterations: 1,
  fillMode: 'forwards',
})

keyframesCSS.briefAppearance = () => keyframes`
  48% {
    transform: rotate(0) scale(1, 1);
  }
  52% {
    transform: rotate(0) scale(1, 1);
  }
  100% {
    transform: rotate(-540) scale(0, 0);
  }
`
animate.briefAppearance = () => animationCSS({
  animation: keyframesCSS.briefAppearance(),
  duration: 1,
  timing: 'linear',
  delay: 0.5,
  iterations: 1,
  fillMode: 'none',
})

keyframesCSS.rememberTheGlow = () => keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8, 0.8);
  }
  25% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
    transform: scale(1.5, 1.5);
  }
`
animate.rememberTheGlow = ({ delay = 0 } = {}) => animationCSS({
  animation: keyframesCSS.rememberTheGlow(),
  duration: 2.5,
  timing: 'ease-in',
  iterations: 'infinite',
  delay,
})

keyframesCSS.opacityPulse = (oFrom = 0, oTo = 1) => keyframes`
  from {
    opacity: ${oFrom};
  }
  to {
    opacity: ${oTo};
  }
`
animate.opacityPulse = ({
  oFrom = 0,
  oTo = 1,
  duration = 1,
  timing = 'ease-in-out',
  delay = 0,
  iterations = 'infinite',
  direction = 'alternate',
  fillMode = 'forwards',
} = {}) => animationCSS({
  animation: keyframesCSS.opacityPulse({ oFrom, oTo }),
  duration, timing, delay, iterations, direction, fillMode,
})

export default animate
