import React from 'react'

const Arrow = () => (
  <svg className='shopArrow'
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 36.96 38.82"
  >
    <polygon points="34.76 16.89 32.56 14.85 29.5 11.99 27.29 9.94 26.59 9.29 26.59 6.01 26.59 3.01 26.59 0.01 23.59 0.01 20.59 0.01 16.4 0 16.39 0 16.39 0 13.39 0 10.4 0 10.39 2.99 10.38 5.99 10.38 9.28 9.67 9.94 7.47 11.98 4.39 14.86 2.2 16.89 0 18.93 2.04 21.13 4.08 23.33 14.08 34.08 16.28 36.45 18.48 38.82 20.68 36.45 22.88 34.08 32.88 23.33 34.92 21.13 36.96 18.93 34.76 16.89" />
    <polygon fill="aqua"
      points="16.38 6 16.37 21.56 11.56 16.38 8.48 19.25 18.48 30 28.48 19.25 25.4 16.38 20.58 21.57 20.59 6.01 16.38 6"
    />
  </svg>
)

export default Arrow
