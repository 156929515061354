import { format, isFuture } from 'date-fns'

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({ slug }) {
  return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture ({ publishedAt }) {
  return !isFuture(publishedAt)
}

export function getBlogUrl (publishedAt, slug) {
  return `/blog/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export const loadJavaScriptInto = (parentId, scriptId, scriptSrc, callback) => {
  if (typeof window !== 'undefined') {
    const newScriptId = scriptId || `${parentId}-xscript`
    const scriptExists = document.getElementById(newScriptId)
    if (!scriptExists) {
      const script = document.createElement('script')
      if (scriptSrc.indexOf('http') === 0 || scriptSrc.indexOf('/') === 0 || scriptSrc.indexOf('.') === 0) {
        script.src = scriptSrc
      } else {
        script.innerHTML = scriptSrc
      }
      // script.async = false
      script.id = newScriptId
      document.getElementById(parentId).prepend(script)
      script.onload = () => {
        if (callback) callback()
      }
    }
    if (scriptExists && callback) callback()
  }
}

export const openURL = (url, newTab = true) => {
  if (typeof window !== 'undefined') {
    if (newTab) {
      window.open(url, '_blank')
    } else {
      window.location.href = url
    }
    return true
  } else {
    return false
  }
}

// export const pullRando = (mn = 0, mx = 1) => Math.floor(Math.random() * (mx - mn + 1) + mn)
export const pullRando = (mn = 0, mx = 1, inc = 1) => {
  // var precision = 100 // 2 decimals
  // var randomnum = Math.floor(Math.random() * (10 * precision - 1 * precision) + 1 * precision) / (1 * precision)

  const totalSteps = (mx - mn) / inc
  const possibleValues = []
  for (let step = 0; step <= totalSteps; step += inc) {
    possibleValues[step] = mn + (step * inc)
  }
  let rando = Math.floor(Math.random() * (totalSteps + 1))
  rando = possibleValues[rando]
  rando = (parseInt(inc, 10) === inc) ? Math.floor(rando) : rando.toFixed(2)
  // console.log(mn, mx, totalSteps, inc, rando)
  return (rando)
}

export const randomColor = (as = 'hex') => {
  const rgb = []
  switch (as) {
    case 'hex':
    default:
      for (let h = 0; h < 3; h++) rgb[h] = padStr(pullRando(0, 255).toString(16))
      return `#${rgb.join('')}`
  }
}

export const shuffleArray = (arr) => {
  const ar = [ ...arr ]
  for (let i = ar.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [ ar[i], ar[j] ] = [ ar[j], ar[i] ]
  }
  return ar
}

export const upperCaseFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1)

export const padStr = (input, padLen = 2, padWith = '0', start = true) => start ? input.toString().padStart(padLen, padWith) : input.toString().padEnd(padLen, padWith)

export const roundNumberTo = (num, dec = 0) => Math.round((num + Number.EPSILON) * Math.pow(10, dec)) / Math.pow(10, dec)

export const alphaRay = () => {
  const alphaLtrs = []
  let alphaW = 0
  for (let a = 65; a <= 90; a++) {
    let ltr = String.fromCharCode(a)
    if (a === 87) {
      switch (alphaW) {
        case 0:
          a = 86
        // eslint-disable-next-line no-fallthrough
        default:
          alphaW++
          ltr += alphaW
      }
    }
    alphaLtrs.push(ltr)
  }
  return alphaLtrs
}

export const toQueryString = (paramsObject) => Object
  .keys(paramsObject)
  .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
  .join('&')

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval (callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [ callback ]) // RUN ON LOAD AND WHEN VARS UPDATE

  // Set up the interval.
  useEffect(() => {
    function tick () {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [ delay ]) // RUN ON LOAD AND WHEN VARS UPDATE
}

// import TimeTime from 'some-universe/where-this/doesnt/slow-your-animation/to-a-crawl'

// IN COMPONENT // Looks like it's TIME for A timeTime
// const [timeTime, setTimeTime] = useState((0).toFixed(2));
// useInterval(() => {
//   setTimeTime((Math.round((BannerTimeline.totalTime() + Number.EPSILON) * 100) / 100).toFixed(2));
// }, 100);

// IN RENDER
// <TimeTime>{timeTime}</TimeTime>
